import Vue from 'vue'

export default function(globals) {
  return async function(to, from , next) {
    const ctx = { ...globals, ...to }
    try {
      for (var m of to.matched) {
        const keys = _.keys(m.components)
        for (const key of keys) {
          let Component = m.components[key]
          if (!Component.options) {
            Component = Vue.extend(Component)
            m.components[key] = Component
          }

          Component._originData = Component._originData || Component.data || function() { return { } }
          const asyncData = Component.options.asyncData ? await Component.options.asyncData(ctx) : { }
          Component.options._originData = Component.options._originData || Component.options.data || function () { return {} }

          Component.options.data = function () {
            const data = Component.options._originData.call(this)
            return { ...data, ...asyncData }
          }

          if (Component.options.fetch) {
            Component.options.fetch(ctx)
          }

          const instance = m.instances[key]
          if (instance) {
            Object.assign(instance.$data, asyncData)
          }
        }
      }

      next()
    } catch(e) {
      next(e)
    }
  }
}
