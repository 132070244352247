export default  function(globals) {
  return async function(to, from , next) {
    if (_.includes([ '/login', '/logout', '/success', '/compatible', '/reset_password' ], _.trimEnd(to.path, '/'))) {
      return next()
    }

    try {
      await globals.store.dispatch("account/fetch")
    } catch(error) {
      if (error.response?.status == 401) {
        return next({
          name: 'login'
        })
      }
    }
  
    return next()
  }
}
