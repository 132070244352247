import _ from 'lodash'
import ValidationFactory from '../ValidationFactory'

const context = require.context("./", false, /.js/)

const paths = context.keys()

const result = _.reduce(paths, function(result, path) {
  const fragments = path.split("/")
  const filename = _.last(fragments)

  if ([ "index.js" ].includes(filename)) {
    return result
  }

  const name = _.replace(filename, /\.js$/, "")
  const Request = context(path).default
  result[name] = function(...args) { return new Request(...args) }
  return result
}, { })

result.processResponse = function(error, validations) {
  const response = error.response
  if (!response || response.status != 422) {
    return false
  }

  validations.clear()
  const errors = response.data.errors
  for (const name in errors || {}) {
    const messages = errors[name]
    for (const message of messages) {
      validations.get(name).invalid(message)
    }
  }

  return true
}

export default result
