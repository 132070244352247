import Vue from 'vue'
import Vuex from 'vuex'
import $ from 'jquery'
import _ from 'lodash'
import store from './store'
import router from './router'

import requests from '@/lib/requests'
import auth from './middleware/auth'
import axios from './plugins/axios'
import cache from './plugins/cache'
import prefetcher from './plugins/prefetcher'
import DashboardPlugin from './plugins/dashboard-plugin'
import './plugins/constants'
import './plugins/moment'

import App from './App.vue'

Vue.use(DashboardPlugin)

Vuex.Store.prototype.$cache = Vue.prototype.$cache = cache
Vuex.Store.prototype.$axios = Vue.prototype.$axios = axios
Vuex.Store.prototype.$requests = Vue.prototype.$requests = requests

router.beforeEach(auth({ store, axios, cache }))
router.beforeResolve(prefetcher({ store, axios, cache, requests, loading: Vue.prototype.$loading }))

$(function() {
  const instance = new Vue({ el: '#app', render: h => h(App), router, store })
  window.app = instance
})
