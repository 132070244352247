import BaseRequest from "./BaseRequest"

export default class extends BaseRequest {
  doSetup() {
    this.method = "post",
    this.urlTemplate = "/sign_in"
  }
  async doPerform(params) {
    return await super.doPerform(params)
  }
}
