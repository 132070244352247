import Vue from 'vue'
import VueRouter from 'vue-router'
import autoRoutes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
import _ from 'lodash'

Vue.use(VueRouter)

const routes = autoRoutes.filter((item) => {
  const fragments = item.path.split("/")
  const fragment = _.last(fragments)
  return fragment == fragment.toLowerCase()
})

const RouterLayout = createRouterLayout((layout) => {
  return import('./layouts/' + layout + '.vue')
})

export default new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes,
      
    }
  ]
})
