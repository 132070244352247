import requests from '@/lib/requests'

const state = {
  list: null,
  current: null,
  user: {
    loggedIn: false,
    isSubscribed: false
  }
}

const getters = {
  current(state) {
    return state.current
  },
  auth(state) {
    return state.user
  }
}

const mutations = {
  SET_LIST(state, value) {
    state.list = value
  },
  SET_CURRENT(state, value) {
    state.current = value
  }
}

const actions = {
  async fetch({ state, commit }) {
    if (state.current == null) {
      const account = await requests.AccountGet(this).setup().perform()
      commit("SET_CURRENT", account)
      return account
    } else {
      return state.current
    }
  },
  async list({ state, commit }) {
    if (state.list == null) {
      const accounts = await requests.AccountList(this).setup().perform()
      commit("SET_LIST", accounts)
      return accounts
    } else {
      return state.list
    }
  },
  async logout() {
    try {
      await requests.LogoutGet(this).setup().perform()
      commit("SET_CURRENT", null)
    } catch(error) {
      // no select
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
