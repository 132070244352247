import Vue from 'vue'
import _ from 'lodash'

const constants = {
  FMT_DATE: 'YYYY-MM-DD',
  FMT_DATETIME_HOUR: 'YYYY-MM-DD HH:mm',
  FMT_DATETIME_HOUR_SECOND: 'YYYY-MM-DD HH:mm:ss',
}

Vue.mixin({
  data: function() {
    return constants
  },
  computed: {
    _() { return _ }
  }
})

export default constants
