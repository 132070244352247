import URI from 'urijs'
import URITemplate from 'urijs/src/URITemplate'
import qs from 'qs'

export default class {
  interpolations = {}
  query = {}

  constructor(ctx) {
    this.$cache = ctx.cache || ctx.$cache
    this.$axios = ctx.axios || ctx.$axios
  }

  setup(callback = null) {
    this.doSetup()

    if (callback) {
      callback(this)
    }

    this.url = this.buildUrl()
    return this
  }

  doSetup() {
  }

  buildUrl() {
    const url = new URITemplate(this.urlTemplate).expand(this.interpolations)
    const uri = new URI(url)
    const queryString = this.paramsSerializer(this.query)
    return uri.query(queryString).toString()
  }

  async perform(params = {}) {
    const cacheValue = this.getCacheValue()
    if (cacheValue) {
      return cacheValue
    }

    try {
      const resp = await this.doPerform(params)
      const result = this.processResponse(resp)

      this.setCacheValue(result)
      this.cleanupCache(result)

      return result
    } catch (e) {
      return await this.processError(e)
    }
  }

  async processError(e) {
    throw e
  }

  async doPerform(params) {
    return await this.$axios.request({
      url: this.url,
      method: this.method,
      data: params,
      // headers: this.headers,
      paramsSerializer: this.paramsSerializer
    })
  }

  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }


  processResponse(response) {
    return response.data
  }

  buildCacheKey() {
    if (this.method != "GET") {
      return null
    }

    return this.url
  }

  getCacheValue() {
    const cacheKey = this.buildCacheKey()
    if (!cacheKey) { return null }

    return this.$cache.get(cacheKey)
  }

  setCacheValue(result) {
    const cacheKey = this.buildCacheKey()
    if (!cacheKey) { return }

    this.$cache.set(cacheKey, result)
  }

  cleanupCache(result) {
    if (this.method != "GET") {
      this.$cache.flushAll()
    }
  }
}
