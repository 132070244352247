import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

Vue.use(Vuex)

const context = require.context("./modules", false, /\.js$/)
const modules = _.reduce(context.keys(), function(result, path) {
  const key = _.flow(
    _.partialRight(_.replace, /.js$/, ""),
    _.partialRight(_.replace, /^\.\//, "")
  )(path)
  result[key] = context(path).default
  return result
}, { })

export default new Vuex.Store({
  modules,
  strict: true
})
